<template>
  <div class="page1">
    <!-- <infoList :list="baseList" :result="result">基本信息</infoList>
        <div class="title">证照信息
        <el-button type="primary" size="mini" class="ml10" @click="addLicence">添加证照</el-button></div>
        <infoList :list="item.baseList" :result="item.result" v-for="(item,index) in licenceList" :key="index">
            <div slot="small-title" class="small-title">
                证照{{index+1}} 信息
            </div>
        </infoList> -->
    <infoList :list="basicBaseList" :result="result">基本信息</infoList>
   <!--证照信息-->
<!--    <div class="title">证照信息</div>-->
<!--    <div v-for="item in result.cardPhotoList">-->
<!--      <infoList :list="photoBaseList" :result="item" v-if="item.tenantCardPhotoName=='营业执照'"></infoList>-->
<!--      <infoList :list="photoBaseList2" :result="item" v-else></infoList>-->
<!--    </div>-->
    <!--商户信息变更历史-->
    <div class="title">商户信息变更历史</div>
    <div v-for="item in result.tenantChangeList" >
      <infoList :list="tenantChangeList" :result="item"></infoList>
    </div>
   <!--合同信息-->
    <div class="title">合同信息</div>
    <div v-for="item in result.contractList" >
      <infoList :list="contractBaseList" :result="item"></infoList>
    </div>

    <!--缴费信息-->
<!--    <div class="title">缴费信息</div>-->
<!--    <ETable :tableTitle="tableTitle"-->
<!--            :tableData="tableData"-->
<!--            :needPagination="false"-->
<!--    >-->
<!--    </ETable>-->
<!--    <infoList :list="payBaseList" :result="result"></infoList>-->
    <!-- 选择证照名称弹窗 -->
    <EDialog :dialogVisible="dialogVisible" title="选择证照" @handleClose="cancelDialog" width="30%"
             @handleClick="onSummit(dialogForm.tenantCardPhotoName)">
      <EForm :formColumns="dialogFormColumns" :rowSize="1" :optionsBtn="false" :actionBtn="false" :formData="dialogForm"
             ref="form" :searchFlag="false" :labelPosition="labelPosition" :labelWidth="labelWidth"
             :formRules="formRules">
      </EForm>
    </EDialog>

  </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import infoList from '@/components/infoList'
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";

  export default {
    name: 'shopBasicInfoDetail',
    data() {
      return {
        id: this.$route.query.id,
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '100px',
        formColumns: [
          {
            title: '商户名称',
            property: 'tenantName',
            type: 'input',
            placeholder: '商户名称',
            show: true
          },
          {
            title: '联系电话',
            property: 'phone',
            type: 'input',
            placeholder: '例如：0571-88888888',
            show: true
          },
          {
            title: '负责人姓名',
            property: 'signPerson',
            type: 'input',
            placeholder: '真实姓名',
            show: true
          },
          {
            title: '经营范围',
            property: 'ent_purse_balance_total',
            type: 'select',
            show: true
          },
          {
            title: '身份证号码',
            property: 'idCard',
            type: 'input',
            placeholder: '例如：286057188888888',
            show: true
          },
          {
            title: '商户类型',
            property: 'tenantType',
            type: 'select',
            placeholder: '',
            show: true,
            options: [
              {
                label: '个体',
                value: 1
              },
              {
                label: '公司',
                value: 2
              },
              {
                label: '其他',
                value: 3
              },
            ]
          },
          {
            title: '负责人籍贯',
            property: 'birthPlace',
            type: 'input',
            placeholder: '籍贯',
            show: true
          },
          {
            title: '身份证照片',
            property: 'idCardPath',
            type: 'mixinUpLoad',
            show: true
          },
        ],
        dialogFormColumns: [
          {
            title: '证照名称：',
            type: 'select',
            property: 'tenantCardPhotoName',
            placeholder: '',
            show: true,
            options: [
              {
                label: '营业执照',
                value: 1
              }
            ]
          },
        ],
        photoInfoFormColumns: [
          {
            title: '证照编号：',
            type: 'input',
            property: 'placeName',
            placeholder: '',
            show: true,
          },
          {
            title: '有效期自：',
            type: 'datePicker',
            property: 'validBeginDate',
            show: true
          },
          {
            title: '证照名称：',
            type: 'select',
            property: 'tenantCardPhotoName',
            placeholder: '',
            show: true,
            disabled: true,
            options: [
              {
                label: '营业执照',
                value: 1
              }
            ]
          },
          {
            title: '有效期至：',
            type: 'datePicker',
            property: 'validEndDate',
            show: true
          },
          {
            title: '法人代表:',
            type: 'input',
            property: 'placeName',
            placeholder: '',
            show: true,
          },
          {
            title: '身份证号码:',
            type: 'select',
            property: 'idCard',
            placeholder: '',
            show: true,
          },
          {
            title: ' 经营范围：',
            type: 'input',
            property: 'businessScope',
            placeholder: '',
            show: true,
          },
          {
            title: ' 类别：',
            type: 'radio',
            property: 'placeName',
            placeholder: '',
            show: true,
            options: [
              {
                label: '个体',
                value: 1
              },
              {
                label: '公司',
                value: 2
              },
            ]
          },
          {
            title: ' 说明：',
            type: 'textarea',
            property: 'description',
            placeholder: '请输入说明',
            show: true,
          },
          {
            title: '  照片：',
            type: 'mixinUpLoad',
            property: 'photoUrl',
            placeholder: '',
            show: true,
          },
          {
            title: '状态：',
            type: 'select',
            property: 'status',
            placeholder: '',
            show: true,
            options: [
              {
                label: '设立中',
                value: 1
              },
              {
                label: '变更中',
                value: 2
              },
              {
                label: '注销中',
                value: 2
              },
              {
                label: '开业',
                value: 2
              },
              {
                label: '异地',
                value: 2
              },
            ],
          },
        ],
        photoInfoForm: {
          businessScopeId: '',
          validBeginDate: '',
          validEndDate: '',
          tenantCardPhotoName: '',
          idCard: '',
          description: '',
          status: '',
          photoUrl: '',
        },
        basicBaseList: [
          {
            label: '商户名称',
            property: 'tenantName',
          },
          {
            label: '统一社会信用代码',
            property: 'socialCreditCode',
          },
          {
            label: '营业执照地址',
            property: 'businessLicenseAdress',
          },
          {
            label: '开户行及其账号',
            property: 'bankAccount',
          },
          {
            label: '商户类型',
            property: 'tenantTypeCn',
          },
          {
            label: '经营范围',
            property: 'businessScopeName',
          },
          {
            label: '负责人姓名',
            property: 'signPerson',
          },
          {
            label: '身份证号码',
            property: 'idCard',
          },
          {
            label: '联系电话',
            property: 'phone',
          },
          {
            label: '负责人籍贯',
            property: 'birthPlace',
          },
          {
            label: '录入人',
            property: 'createUserCn',
          },
          {
            label: '录入时间',
            property: 'createTime',
          },
          {
            label: '最近一次修改人',
            property: 'updateUserCn',
          },
          {
            label: '最近一次修改时间',
            property: 'updateTime',
          },
          {
            label: '身份证照片',
            property: 'idCardPath',
            type:'imgList'
          },
        ],
        result: {
          tenantName: '',
          idCard: '',
          tenantTypeCn: '',
          signPerson: '',
          phone: '',
          birthPlace: '',
          createUserCn: '',
          createTime: '',
          updateUserCn: '',
          updateTime: '',
          tenantCardPhotoName:'',
          socialCreditCode:'',
          businessLicenseAdress:'',
          bankAccount:''
        },
        tableTitle: [
          {
            label: '商户编号',
            prop: 'tenantCode'
          },
          {
            label: '商户名称',
            prop: 'tenantName'
          },
          {
            label: '商户类型',
            prop: 'tenantType'
          },
          {
            label: '负责人',
            prop: 'signPerson',
          },
          {
            label: '身份证号',
            prop: 'idCard',
          },
          {
            label: '联系电话',
            prop: 'phone',
          },
          {
            label: '录入人',
            prop: 'createUser',
          },
          {
            label: '录入时间',
            prop: 'createTime',
          },
          {
            label: '最近一次修改人',
            prop: 'updateUser',
          },
          {
            label: '修改时间',
            prop: 'updateTime',
          },
        ],
        tableData: [],
        count: null,
        //证照经营
        photoBaseList: [
          {
            label: '名称',
            property: 'operatorName',
          },
          {
            label: '证照名称',
            property: 'tenantCardPhotoName',
          },
          {
            label: '统一社会信用代码',
            property: 'idCard',
          },
          {
            label: '住所',
            property: 'address',
          },
          {
            label: '身份证号码',
            property: 'idCard',
          },
          {
            label: '类别',
            property: 'ttypeCn',
          },
          {
            label: '登记机关',
            property: 'registerOffice',
          },
          {
            label: '经营项目',
            property: 'businessScope',
          },
          {
            label: '签发日期',
            property: 'issuerDate',
          },
          {
            label: '注册资本',
            property: 'registeredCapital',
          },
          {
            label: '经营期限',
            property: 'validDateStr',
          },
          {
            label: '成立日期',
            property: 'registerDate',
          },
          {
            label: '照片',
            property: 'photoUrl',
            type:'imgList'
          },
        ],
        //食品证照
        photoBaseList2: [
          {
            label: '经营者名称',
            property: 'operatorName',
          },
          {
            label: '证照名称',
            property: 'tenantCardPhotoName',
          },
          {
            label: '统一社会信用代码（身份证号码）',
            property: 'idCard',
          },
          {
            label: '许可证编号',
            property: 'tenantCardPhotoCode',
          },
          {
            label: '住所',
            property: 'address',
          },
          {
            label: '日常监督管理机构',
            property: 'superviseOrganization',
          },
          {
            label: '经营场所',
            property: 'premises',
          },
          {
            label: '日常监督管理人员',
            property: 'supervisePerson',
          },
          {
            label: '主体业态',
            property: 'mainWork',
          },
          {
            label: '投诉举报电话',
            property: 'lineTelephone',
          },
          {
            label: '经营项目',
            property: 'businessScope',
          },
          {
            label: '发证机关',
            property: 'registerOffice',
          },
          {
            label: '签发日期',
            property: 'issuerDate',
          },
          {
            label: '签发人',
            property: 'issuerPerson',
          },
          {
            label: '有效期至',
            property: 'validEndDate',
          },
          {
            label: '照片',
            property: 'photoUrl',
            type:'imgList'
          },
        ],
        tenantChangeList:[
          {
            label: '商户名称',
            property: 'tenantName',
          },
          {
            label: '统一社会信用代码',
            property: 'socialCreditCode',
          },
          {
            label: '营业执照地址',
            property: 'businessLicenseAdress',
          },
          {
            label: '开户行及其账号',
            property: 'bankAccount',
          },
          {
            label: '商户类型',
            property: 'tenantTypeCn',
          },
          {
            label: '经营范围',
            property: 'businessScopeName',
          },
          {
            label: '负责人姓名',
            property: 'signPerson',
          },
          {
            label: '身份证号码',
            property: 'idCard',
          },
          {
            label: '联系电话',
            property: 'phone',
          },
          {
            label: '负责人籍贯',
            property: 'birthPlace',
          },
          {
            label: '最后一次变更人',
            property: 'updateUserCn',
          },
          {
            label: '最后一次变更时间',
            property: 'updateTime',
          },
          {
            label: '变更原因',
            property: 'changeReason',
          },
          {
            label: '身份证照片',
            property: 'idCardPath',
            type:'imgList'
          },
        ],
        contractBaseList: [
          {
            label: '合同编号',
            property: 'contractCode',
          },
          {
            label: '主营业务',
            property: 'rentPurposeName',
          },
          // {
          //   label: '签订时间',
          //   property: 'createTime',
          // },
          {
            label: '到期日期',
            property: 'expireDate',
          },
          {
            label: '合同类型',
            property: 'contractTypeCn',
          },
          // {
          //   label: '签订人',
          //   property: 'createUserCn',
          // },
          {
            label: '生效日期',
            property: 'inDate',
          },
          {
            label: '录入人',
            property: 'createUserCn',
          },
          {
            label: '录入时间',
            property: 'createTime',
          },
          {
            label: '最近一次修改人',
            property: 'updateUserCn',
          },
          {
            label: '最近一次修改时间',
            property: 'updateTime',
          },
          {
            label: '区域',
            property: 'areaName',
          },
          {
            label: '位置',
            property: 'placeName',
          },
          {
            label: '摊位号',
            property: 'boothCode',
          },
          {
            label: '摊位类型',
            property: 'boothTypeName',
          },
          {
            label: '面积',
            property: 'areas',
          },
        ],
        payBaseList: [
          {
            label: '收费员',
            property: 'title',
          },
          {
            label: '收费时间时间',
            property: 'title',
          },
          {
            label: '支付方式',
            property: 'title',
          },
          {
            label: '缴费人',
            property: 'title',
          },
          {
            label: '缴费人联系方式',
            property: 'title',
          },
          {
            label: '卡号',
            property: 'title',
          },
        ],
        cardPhotoList: [],
        // licenceList: [
        //   {
        //     baseList: [
        //       {
        //         label: '商户名称',
        //         property: 'title',
        //         type: 'input'
        //       },
        //       {
        //         label: '联系电话',
        //         property: 'mobile',
        //         type: 'input'
        //       },
        //     ],
        //     result: {
        //       title: '',
        //       mobile: ''
        //     }
        //   }
        // ],
        form: {
          tenantName: '',
          phone: '',
          signPerson: '',
          idCard: '',
          tenantType: '',
          birthPlace: '',
          purse_balance_total: ''
        },
        dialogForm: {},
        formRules: {
          tenantName: vxRule(true, '', "blur", "商户名称不能为空"),
          phone: vxRule(true, '', "blur", "联系电话不能为空"),
          signPerson: vxRule(true, '', "blur", "负责人姓名不能为空"),
          idCard: vxRule(true, '', "blur", "身份证号码不能为空"),
        },
      }
    },
    watch: {},
    created() {
      this.getBasicInfoDetail()
    },
    components: {ETable, EButton, EForm, infoList, EDialog},
    beforeMount() {

    },
    methods: {
      addLicence() {
        this.cardPhotoList.push({
          baseList: [
            {
              label: '商户名称',
              property: 'title',
              type: 'input'
            },
            {
              label: '联系电话',
              property: 'mobile',
              type: 'input'
            },
          ],
          result: {
            title: '',
            mobile: ''
          }
        })
      },
      cancelDialog() {
        this.dialogVisible = false
        this.dialogForm = {
          photoName: '',
        }
        this.$refs.form.$refs.form.resetFields()
      },
      onSummit(tenantCardPhotoName) {
        this.photoInfoForm.tenantCardPhotoName = tenantCardPhotoName
        this.dialogVisible = false
        this.cardPhotoList.push(this.photoInfoFormColumns)
      },
      //获取商户证照详情
      async getBasicInfoDetail() {
        let res = await Http.getBasicInfoDetail({id: this.id})
        if (res.code == 200) {
          this.result = res.data
          this.result.idCardPath=this.result.idCardPath.split(',')
          this.result.cardPhotoList.forEach(item=>{
            item.photoUrl=item.photoUrl.split(',')
          })
          this.result.tenantChangeList.forEach(item=>{
            item.idCardPath=item.idCardPath.split(',')
          })
        }
      },
    }
  }
</script>

<style lang="scss">
  .title {
    font-weight: bold;
    padding-left: 10px;
    border-left: 2px solid #4d68ff;
    margin-bottom: 20px;
  }

  .small-title {
    margin-left: 10px;
    margin-top: 20px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

</style>
